@import "modules/responsive-type.scss";

$primary: #d91b5c;/* MAIN COLOR */
$secondary: #354145; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Questrial');
// font-family: 'Alfa Slab One', cursive;
// font-family: 'Questrial', sans-serif;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Questrial', sans-serif;
    font-size: 1rem;
}

h1 {
    font-family: 'Alfa Slab One', cursive;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 { 
font-family: 'Alfa Slab One', cursive; 
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
    font-family: 'Alfa Slab One', cursive;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
            font-family: 'Alfa Slab One', cursive;
            text-align: center;
            margin-top: 30px;
            display: flex;
            align-items: center;
            color: $blk;
            border-radius: $border-radius;
            transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 25px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $blk;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 3em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    font-family: 'Questrial', sans-serif;
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.roundthis{
    border-radius: $border-radius;
}


.shadowthis {
    text-shadow: 0px 0px 8px rgba(999, 999, 999, 0.75);
}


.intro{ 
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.25), 0 -6px 20px 0 rgba(0, 0, 0, 0.25);
    background: url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 200px 0px 100px;



    @media (max-width: 568px) {
        background: none;
        padding: 50px 0px 20px;
    }

    @media (max-width: 320px) {
        background: none;
        padding: 20px 0px 10px;
    }
}

.divider{
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    background:$primary;
    padding: 50px 0px;



    .btn {
      font-family: 'Alfa Slab One', cursive;
      background-color: $blk;
      // border-color: rgba(255,255,255,1); 
      font-size: 20px;
      padding: .5em 1.5em;
      color: $wht;
      // text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);

        &:hover {
            background-color: $wht; 
            //border-color: rgba(255,255,255,1); 
            color: $blk;

            @media (max-width: 1366px) {
                background-color: $blk;
                color: $wht;
            }

            &:active, &:focus {
                background-color: $blk;
                color: $wht !important;
                }
            }
        }

}

.form{    
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.25), 0 -6px 20px 0 rgba(0, 0, 0, 0.2);

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .hideme{
        @media (min-width: 568px){
            display: none;
        }
    }

    .imgcontainer{
        background: url(../img/img1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 80%;
        padding: 180px 0px;

        @media (max-width: 568px) {
        background: none;
        padding: 0px;
        }
    }
}

.divider2{
    // background: url(../img/banner2.jpg);

    h1, p{
        text-shadow: 0px 0px 8px rgba(999, 999, 999, 0.75);
    }

    background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255,255, 255, 0.5)), url(../img/banner2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 80%;
    padding: 200px 0px;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.25), 0 -6px 20px 0 rgba(0, 0, 0, 0.2);


    @media (max-width: 1199px) {
        padding: 60px 0px;

    }

    @media (max-width: 991px) {
        padding: 10px 0px;

    }
    
    @media (max-width: 768px) {
        padding: 0px;
    }

}

.outro{
    // background: url(../img/img2.jpg);
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.25), 0 -6px 20px 0 rgba(0, 0, 0, 0.2);

    background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255,255, 255, 0.5)), url(../img/img2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 100px 0px 300px;

    @media (max-width: 568px) {
        background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255,255, 255, 0.5)), url(../img/lovebrain.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        padding: 120px 0px;
    }

    @media (max-width: 320px) {

        padding: 50px 0px;
    }
}


.btn {
  font-family: 'Alfa Slab One', cursive;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 20px;
  padding: .5em 1.5em;
  color: $wht;
  // text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);

    &:hover {
        background-color: $blk; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $blk;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $blk;
        color: $wht !important;
        }
    }
}